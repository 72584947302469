<template>
    <div>
        <uToast ref="toast"></uToast>
    </div>
</template>

<script>
  
    export default {
        components: {   },
        methods: {
            toast(type, txt, timer) {
                return this.$refs.toast.show(type, txt, timer);
            },
         
        },
    }
</script>

<style lang="scss">
</style>
