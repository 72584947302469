<template>
      <v-app  class="mainApp" >
        <Header  />
        <v-main  style="padding-top: 0px !important; margin-bottom:100px">
            <v-container >
                <router-view ></router-view>
            </v-container>
        </v-main>
        <!-- <Footer v-if="!isShowFooter"  /> -->
         <Cookies @getOpen="openCookies" v-if="isopenCookies" @setState="setStates" />
        <Footer />
    </v-app>
</template>

<script>

import Header from "./header";
import Footer from "./footer";
import Cookies from "../components/cookiesDialog";
import { loginOut } from "../api/home";
export default {
  name: 'Layout',
  
  components: {
    Header,
    Footer,
    Cookies
  },
  data:function(){
    return{
        isopenCookies:true,
    }
  },
  mounted(){
     let cookies = localStorage.getItem("acceptCookie");
   if(cookies ){
      this.isopenCookies = false
   }
  },
  methods:{
      openCookies(e){
      this.isopenCookies = e
    },
    setStates(e){
      if(e){
         console.log("setStates",e);
      }else{
        loginOut()
      }
    },
  },
  computed: {
    // key() {
    //   // 只要保证 key 唯一性就可以了，保证不同页面的 key 不相同
    //   console.log("this.$route.fullPath,",this.$route.fullPath);
    //   return this.$route.fullPath
    // }

      key() {
                return this.$route.name !== undefined ?
                    this.$route.name + new Date() :
                    this.$route + new Date();
            },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1264px){
  .container {
    max-width: 1220px;
}

}


    .mainApp{
        /* background-color: violet !important; */
        background-image: url(@/assets/background.jpg) !important;
        background-size: cover !important;
    }
</style>