<template>
 <Layout />
</template>

<script>
import Layout from "./layout/index";
export default {
  components:{Layout},
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
