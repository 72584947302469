<template>
  <div class="foot">
    <v-footer
      app
      absolute
      class="pa-0 pb-15 pb-lg-5 d-flex flex-column"
      color="#160015"
      :elevation="0"
      :height="height"
    >
      <v-container
        class="d-flex flex-column flex-lg-row py-0 px-10 px-lg-0 px-md-10 mt-lg-8"
      >
        <template v-if="isMobile">
          <div class="mobilefoot">
            <div style="margin-top: 30px"></div>
            <div class="CommunityTitle">Community</div>
            <div class="d-flex justify-space-around mt-8" style="width: 100%">
              <a :href="discodeurl" target="_blank">
                <img
                  src="https://www.uptick.network/assets/img/08/icon_discord.png"
                  style="width: 24px; height: 24px"
                />
              </a>
              <a :href="`https://${mediumurl}`" target="_blank">
                <img
                  src="https://www.uptick.network/assets/img/08/icon_medium.png"
                  class="ml-8"
                  style="width: 24px; height: 24px"
                />
              </a>
              <a :href="twitterurl" target="_blank">
                <img
                  src="https://www.uptick.network/assets/img/08/icon_twitter.png"
                  class="ml-8"
                  style="width: 24px; height: 24px"
                />
              </a>
              <a :href="teleurl" target="_blank">
                <img
                  src="https://www.uptick.network/assets/img/08/icon_telegram.png"
                  class="ml-8"
                  style="width: 24px; height: 24px"
                />
              </a>
              <a :href="`mailto:${mailurl}`" target="_blank">
                <img
                  src="https://www.uptick.network/assets/img/08/icon_email.png"
                  style="width: 24px; height: 24px"
                  class="ml-8"
                />
              </a>
            </div>
            <div class="CommunityTitle mt-12">Newsletter</div>
            <div class="sub d-flex flex-column mt-6 mb-5">
              <input
                id="email"
                type="email"
                v-model="value"
                class="form-control mobileemail"
                placeholder="Enter your email to sign up for the newsletter"
              />
              <button
                class="Subscribe ml-lg-3 ml-0 mt-lg-0 mt-3"
                @click="subscribe"
              >
                Subscribe
              </button>
            </div>
            <div class="CommunityTitle">Validators</div>
              <a
                href="https://discord.com/channels/781005936260939818/946721096399482951"
                target="_blank"
              >
                <img
                  class="mt-5"
                  src="https://www.uptick.network/assets/img/08/icon_discord.png"
                  width="35"
                  alt="discord"
                />
              </a>
               <div class="CommunityTitle mt-5">Developers</div>
              <div class="d-flex flex-row mt-6 align-center">
                <a href="https://github.com/UptickNetwork" target="_blank">
                  <img
                    width="35"
                    src="https://www.uptick.network/assets/img/08/icon-github.png"
                    alt=""
                  />
                </a>
                <a
                  href="https://discord.com/channels/781005936260939818/943431354639601715"
                  target="_blank"
                >
                  <img
                    width="35"
                    height="28"
                    class="ml-10"
                    src="https://www.uptick.network/assets/img/08/icon_discord.png"
                    alt=""
                  />
                </a>
              </div>
             <div class="about mt-7" style="width:100%">
              <div class="CommunityTitle">Developers</div>
              <div class="mobileDeveloper"><a href="https://blog.uptickproject.com/" target="_blank" >Blog</a></div>
              <div class="mobileDeveloper"><a href="https://docs.uptick.network/" target="_blank">Docs </a></div>
                <div class="mobileDeveloper"><a href="https://docs.uptick.network/mainnet/explorer.html" target="_blank">Explorer </a></div>
             <div class="mobileDeveloper"><a href="https://github.com/UptickNetwork/Uptick-KB" target="_blank">White Paper </a></div>
            <div class="mobileDeveloper">Mainnet Faucet</div>
             <div class="mobileDeveloper"><a href="https://discord.com/channels/781005936260939818/953652276508119060" target="_blank">Testnet Faucet </a></div>
            <div class="mobileDeveloper">Devs</div>
              
            </div>
           
            <Logo height="43px" class="mt-10" />
            <div class="mobilecopyright">
              Copyright ©2019-2023 Uptick Network. All rights reserved.
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="d-flex flex-row justify-space-between"
            style="width: 100%"
          >
            <div class="footer d-flex flex-column">
              <div class="community">
                <div class="CommunityTitle">Community</div>
                <div class="icon mt-6">
                  <a :href="discodeurl" target="_blank">
                    <img
                      src="https://www.uptick.network/assets/img/08/icon_discord.png"
                      style="width: 35px"
                    />
                  </a>
                  <a :href="`https://${mediumurl}`" target="_blank">
                    <img
                      src="https://www.uptick.network/assets/img/08/icon_medium.png"
                      class="ml-8"
                      style="width: 35px"
                    />
                  </a>
                  <a :href="twitterurl" target="_blank">
                    <img
                      src="https://www.uptick.network/assets/img/08/icon_twitter.png"
                      class="ml-8"
                      style="width: 35px"
                    />
                  </a>
                  <a :href="teleurl" target="_blank">
                    <img
                      src="https://www.uptick.network/assets/img/08/icon_telegram.png"
                      class="ml-8"
                      style="width: 35px"
                    />
                  </a>
                  <a :href="`mailto:${mailurl}`" target="_blank">
                    <img
                      src="https://www.uptick.network/assets/img/08/icon_email.png"
                      style="width: 35px"
                      class="ml-8"
                    />
                  </a>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="CommunityTitle mt-lg-16">Newsletter</div>
                <div class="sub mt-5">
                  <input
                    id="email"
                    type="email"
                    v-model="value"
                    class="form-control email"
                    placeholder="Enter your email to sign up for the newsletter"
                  />
                  <button class="Subscribe ml-4" @click="subscribe">
                    Subscribe
                  </button>
                </div>
              </div>
              <div class="logo d-flex flex-row mt-lg-16">
                <Logo height="50px" width="130px" />
                <div class="copyright">
                  Copyright ©2019-2023 Uptick Network. All rights reserved.
                </div>
              </div>
              <!-- <div class="about ml-4 d-flex flex-column">
            <a href="https://www.uptick.network/#/" target="_blank" class="aboutTitle">{{$t('FooterAbout')}}</a>
            <a href="https://blog.uptickproject.com/" target="_blank" class="aboutTitle mt-8">{{$t('FooterBlog')}}</a>
             <div class="aboutTitle mt-8">{{$t('footer_faq')}}</div>
            </div>  -->
            </div>
            <div class="about mt-7" style="width:40%">
              <div class="CommunityTitle">Validators</div>
              <a
                href="https://discord.com/channels/781005936260939818/946721096399482951"
                target="_blank"
              >
                <img
                  class="mt-lg-8"
                  src="https://www.uptick.network/assets/img/08/icon_discord.png"
                  width="35"
                  alt="discord"
                />
              </a>
              <div class="CommunityTitle mt-16">Developers</div>
              <div class="d-flex flex-lg-row mt-lg-6 align-center">
                <a href="https://github.com/UptickNetwork" target="_blank">
                  <img
                    width="35"
                    src="https://www.uptick.network/assets/img/08/icon-github.png"
                    alt=""
                  />
                </a>
                <a
                  href="https://discord.com/channels/781005936260939818/943431354639601715"
                  target="_blank"
                >
                  <img
                    width="35"
                    height="28"
                    class="ml-10"
                    src="https://www.uptick.network/assets/img/08/icon_discord.png"
                    alt=""
                  />
                </a>
              </div>

            </div>
            <div class="about mt-7" style="width:40%">
              <div class="CommunityTitle">Developers</div>
              <div class="Developer"><a href="https://blog.uptickproject.com/" target="_blank" >Blog</a></div>
              <div class="Developer"><a href="https://docs.uptick.network/" target="_blank">Docs </a></div>
                <div class="Developer"><a href="https://docs.uptick.network/mainnet/explorer.html" target="_blank">Explorer </a></div>
             <div class="Developer"><a href="https://github.com/UptickNetwork/Uptick-KB" target="_blank">White Paper </a></div>
            <div class="Developer">Mainnet Faucet</div>
             <div class="Developer"><a href="https://discord.com/channels/781005936260939818/953652276508119060" target="_blank">Testnet Faucet </a></div>
            <div class="Developer">Devs</div>
              
            </div>
          </div>

          <uComponents ref="ucom"></uComponents>
        </template>
      </v-container>
    </v-footer>
  </div>
</template>

<script lang="js">
import Logo from "@/components/footLogo/index.vue";
import { Newsletter } from "../api/home";
// import api from "@/api";

  export default {
    name: "Footers",
    components: { Logo },
    props: {},
    data: function() {
      return {
        height:460,
        value:'',
       discodeurl:"https://discord.com/invite/teqX78VZUV",
        mediumurl: "uptickproject.medium.com",
        twitterurl:'https://twitter.com/Uptickproject',
        teleurl:'https://t.me/uptickproject',
        mailurl:'hello@uptickproject.com'
      };
    },
    computed: {
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    mounted(){
      if(this.isMobile){
        this.height = 900
      }else{
        this.height = 460
      }

    },
    methods: {
    
    async  subscribe(){
        let params = {
          email: this.value,
					channel:3
        }
      let res =  await Newsletter(params);
      if(res.success){
         this.$toast("success", this.$t("success"))
         this.value = ''
      }else{
         this.$toast("error", res.data.msg)
      }

      },
	  todownload(){
		  this.$router.push({ name: "Download" });
	  },
    
      openmedium() {
        window.open().opener = null;
        window.location.href = this.url;
      },
      opentwitter() {
        window.open().opener = null;
        window.location.href = this.url;
      },
      opentelegram() {
        window.open().opener = null;
        window.location.href = this.url;
      },
      toHelp(){
        this.$router.push({name:'HelpCenter'})
      },
      toPrivacy(){
        if(this.$vuetify.lang.current == 'en'){
          this.$router.push({name:'Privacy'})
        }else if(this.$vuetify.lang.current == 'zh'){
          this.$router.push({name:'Privacyzh'})
        }else if(this.$vuetify.lang.current == 'ja'){
          this.$router.push({name:'Privacy'})
        }else if(this.$vuetify.lang.current == 'ko'){
          this.$router.push({name:'Privacy'})
        }else if(this.$vuetify.lang.current == 'pt'){
          this.$router.push({name:'Privacy'})
        }
      },
      toTerms(){

          if(this.$vuetify.lang.current == 'en'){
          this.$router.push({name:'Term'})
        }else if(this.$vuetify.lang.current == 'zh'){
          this.$router.push({name:'Termzh'})
        }else if(this.$vuetify.lang.current == 'ja'){
          this.$router.push({name:'Term'})
        }else if(this.$vuetify.lang.current == 'ko'){
          this.$router.push({name:'Term'})
        }else if(this.$vuetify.lang.current == 'pt'){
          this.$router.push({name:'Term'})
        }

      }
    }
  };
</script>

<style lang="scss" scoped>
.mobilecopyright {
  min-width: 340px;
  font-family: Helvetica;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
}

.mobileOptions {
  a {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: 0px;
    color: #ffffff;
  }
}

.right {
  margin-left: 200px;
  .contant {
    margin-top: 27px;
    img {
      width: 110px;
      height: 110px;
    }
    .download {
      width: 138px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
      margin-bottom: 10px;
    }
    a {
      width: 150px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}
.aboutTitle {
  text-decoration: none;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  width: 120px;
}
.logo {
  margin-top: 10px;
  text-align: center;
  align-items: center;
  .copyright {
    margin-left: 22px;
    min-width: 416px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: 0px;
    color: #ffffff;
  }
}
.Subscribe {
  width: 111px;
  height: 41px;
  background-image: linear-gradient(
    90deg,
    #b633f1 0%,
    #dc539c 50%,
    #ff704a 100%
  );
  border-radius: 5px;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
}
.email:focus {
  color: #212529;
  background-color: #fff;
  outline: 0;
  box-shadow: none;
  border: 1px solid #191b77;
  outline: none;
}
.email {
  width: 340px;
  height: 41px;
  padding: 10px 0.75rem;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #666666;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #9330e7;
}
.mobileemail {
  width: 320px;
  height: 41px;
  padding: 10px 0.75rem;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #666666;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #9330e7;
}
.mobileemail:focus {
  color: #212529;
  background-color: #fff;
  outline: 0;
  box-shadow: none;
  border: 1px solid #191b77;
  outline: none;
}

.CommunityTitle {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #ffffff;
}
.Developer{
       margin-top: 20px;
       color: #999999;
    a{
     
       text-decoration: none;
        font-family: Helvetica;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #999999;
    }
   
}
.mobileDeveloper{
    margin-top: 10px;
       color: #999999;
    a{
     
       text-decoration: none;
        font-family: Helvetica;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #999999;
    }

}
.faucet {
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
}
.fotnav {
  max-width: 1220px;
  margin: 25px auto 16px;

  .left {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    .pointer {
      cursor: pointer;
    }
  }
  .right {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    text-align: right;
    .pointer {
      cursor: pointer;
    }
  }
}
.fotmobile {
  width: 320px;
  margin: 25px auto 16px;

  .left {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    .pointer {
      cursor: pointer;
    }
  }
  .right {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    text-align: right;
    .pointer {
      cursor: pointer;
    }
  }

  .base-box {
    width: 100%;
    // border: solid 1px red;
    margin-bottom: -40px;
  }

  .top-item {
    // width: 100%;
    // margin: 0,auto;
    font-weight: 600;
    margin-bottom: 20px;
    // border: solid 1px black;
    text-align: center;
  }

  .bottom-item {
    // width: 100%;
    // margin: 0,auto;
    // font-weight: 600;
    margin-bottom: 20px;
    // border: solid 1px black;
    text-align: center;
  }

  .last-item {
    text-align: center;
  }
}
</style>
