<template>
  <div class="header d-flex justify-space-between align-center" :class="{ mobile: isMobile }">
    <img class="logo" src="@/assets/Uptick Network logo.svg" alt=""  @click="openHome"/>
    <div class="userInfo d-flex flex-row">
     
      <div class="status">
        <div class="name d-flex align-center ml-3" v-if="userInfo.code == 10003" @click="toSignIn">
           <img class="user mr-2 " src="@/assets/icon_user.png" alt="" />
          Sign In
          </div>
        <div class="Sign ml-3 d-flex flex-row" v-else  @click="showList">
           <img class="user mr-2" src="@/assets/icon_user.png" alt="" />
            <div class="name">{{userInfo.data?userInfo.data.name :'' }}</div>
           <v-icon class="ml-2 mt-n1" color="white" v-if="isShow">mdi-menu-up</v-icon>
          <v-icon class="ml-2 mt-n1" color="white" v-else >mdi-menu-down</v-icon>
        <div class="list" v-if="isShow">
            <div class="items">
                  <div class="item"  v-for="(item) in list" :key="item.id">
                 <div class="name" @click="menuChange(item.route)">{{item.name}}</div>
            </div>
            </div>
          
        </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import { removeCookies } from "../utils/cookie";
import { loginOut,getMyInfo } from "../api/home";
export default {
  name: "Header",
  components: {},
  data:function () {
      return {
         status:2,
         isShow:false,
         userInfo:{},
         list:[
             {
                 name:'My Profile',
                 id:0,
                 route:'user'
                
             },
             {
                 name:'API-KEYs',
                 id:1,
                 route:'apikey'
                
             },
             {
                 name:'Sign Out',
                 is:2,
                  route:'Home'
                
             },
         ]
      }
      
  },
  	computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         },
      },
  async mounted(){
    console.log("hEader");
       window.eventBus.$on('disconnect', this.disConnect);
       let user =  await getMyInfo()
       this.userInfo = user.data

  },
      methods:{
        openHome(){
          //https://www.uptick.network/
        window.open("https://www.uptick.network/","_black")
    },
       async disConnect(){
          let user =  await getMyInfo()
       this.userInfo = user.data
        },
        toSignIn(){
          this.$router.push({name:"Home"})
        },
        showList(){
          this.isShow = !this.isShow
        },
       async menuChange(route){
          if(route == 'Home'){
            await loginOut();
            //  removeCookies('userPsswd')
             
              this.$router.push({name:"Home"})
          }else{
             this.$router.push({name:"myInfo",query:{"source":route}})
          }
         
          
          this.isShow = false

        }
      }
};
</script>

<style lang="scss" scoped>
.header {
  margin: 20px auto 0;
  padding: 0;
  width: calc(100% - 220px);
    .name {
        cursor: pointer;
      font-family: AvenirNext-Medium;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  .logo {
      
    width: 110px;
    height: 46px;
  }
  .userInfo {
    .user {
        cursor: pointer;
        width: 15px;
        height: 15px;
    }
  }
  .status {
  
    .Sign{
        position: relative;
    .list{
        position: absolute;
        top: 20px;
        right: 7px;
        width: 119px;
        height: 160px;
        background-color: #a443f6;
        border-radius: 5px;
        z-index: 10;
        .items{
            width: 100%;
 
              margin: 20px 17px ;
               .item{
            
            .name{
                font-family: AvenirNext-Medium;
                font-size: 15px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 40px;
                letter-spacing: 0px;
                color: #ffffff;

            }
        }
        }
      
       
    }
        
    }
    
  }
  &.mobile{
     width: calc(100% - 30px);
  }
}
</style>
   
