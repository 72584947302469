import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/home";

Vue.use(VueRouter);
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
   if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject);
   return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
   if (onResolve || onReject)
      return originalReplace.call(this, location, onResolve, onReject);
   return originalReplace.call(this, location).catch(err => err);
};

const routes = [
   {
      path: "/",
      redirect: '/index',
   },
   {
      path: "/index",
      name: "Home",
      component: Home,
   },
   {
      path: "/emailnotification",
      name: "emailnotification",
      component: () => import("@/components/apikey"),
   },
   {
      path: "/reconfirmation",
      name: "reconfirmation",
      component: () => import("@/components/reconfirmation"),
   },

   {
      path: "/findPassword",
      name: "findPassword",
      component: () => import("@/components/forgetPassword"),
   },
   //verifiedSuccess
   {
      path: "/verifiedSuccess",
      name: "verifiedSuccess",
      component: () => import("@/components/verifiedSuccess"),
   },
   //register.vue
   {
      path: "/register",
      name: "register",
      component: () => import("@/components/register"),
   },
   {
      path: "/myInfo",
      name: "myInfo",
      component: () => import("@/components/myInfo"),
   },
   // {
   //    path: "/myInfo",
   //    name: "myInfo",
   //    component: () => import("@/components/myInfo"),
   // },
   
   // {
   //    path: "/apikey",
   //    name: "apikey",
   //    meta: {
   //       requireAuth: true //表示该路由需要登录验证后才能进入  在切换路径跳转路由之前作判断
   //     },
   //    component: () => import("@/components/myInfo"),
   // },

];



const router = new VueRouter({
  mode: 'history',
   base: process.env.BASE_URL,
   routes,
   scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
         return savedPosition
      } else {
         return { x: 0, y: 0 }
      }
   }
});

export default router;
