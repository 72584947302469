<template>
  <div class="contant d-flex" :class="{ mobile: isMobile }">
    <div class="logoin">
      <div class="title">Welcome back</div>
      <div class="title-13 mt-6">Login to your account</div>
      <div class="title-15 mt-11">Email Address</div>
      <div class="name mt-4">
        <v-text-field
          ref="nameValue"
          v-model="nameValue"
          :rules="nameRules"
          label=""
          outlined
        >
        </v-text-field>
      </div>
      <div class="passwd d-flex justify-space-between align-center">
        <div class="title-15 mt-10">Password</div>
        <div class="forgetpasswd mt-10" @click="forgetPasswd">
          Forgot your password?
        </div>
      </div>

      <div class="name mt-4">
        <v-text-field
          ref="password"
          type="password"
           :rules="passwdRules"
          v-model.trim="password"
          label=""
          outlined
        >
        </v-text-field>
      </div>
      <div class="check d-flex flex-row mt-3">
        <v-checkbox v-model="checkValue"></v-checkbox>
        <div class="title-13 mt-5">Remember & Auto Login</div>
        <!-- <input type="checkbox" class="checkbox" > -->
      </div>
      <div class="foot mt-2">
        <button class="login" @click="Login">Login</button>
      </div>
      <div class="notAccount mt-9">
        Don't have an account? <span @click="toSign">Click to sign up</span>
      </div>
    </div>
   
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import {login,getMyInfo} from "@/api/home";

import { getCookies,setCookies,removeCookies } from "../utils/cookie";

const privateKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCOIqtslCHtxOlEfEacw0OmYtbLAs5R+AvPCAVeF8ccPDJZtJU2UpABxg0l/10CFDVYUlI/FnVnhl8eOfDnDMATgVGGwZMI+nB7h09RynshQjOfPRPqgNUSVkMkfCdbz8JdVyC8nnZvMbbRYYpt2NB0G9XUqgSEWwBJ1MP56QNtcwIDAQXS";
export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      isopenCookies:true,
      nameValue: "",
      password: "",
      checkValue: false,
      nameRules: [(v) => !!v || "Username is invalid."],
      passwdRules:[(v) => !!v || "Password is invalid.", (v) => (/^\S*$/.test(v)) || "Password does not allow spaces"]
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  async mounted() {
    window.eventBus.$emit("disconnect");
   debugger

    // 判断是否接受cookie是否接受
   let cookies = localStorage.getItem("acceptCookie");
   if(cookies == 'true'){
     // 获取用户信息
    let res = await getMyInfo();
    this.userInfo = res.data.data;
    if (res.data.data) {
       this.$router.push({ name: "myInfo",query:{"source":'user'} });
    }else{
   let userName = getCookies("userName") ? getCookies("userName") : "";
    this.nameValue = userName;
    let enPassword = getCookies("userPsswd") ? getCookies("userPsswd") : "";
    console.log("enPassword",enPassword);
      if (enPassword) {
      // 对密码进行解密
      this.password = CryptoJS.AES.decrypt(enPassword, privateKey).toString(
        CryptoJS.enc.Utf8
      );
      // 将是否记住密码置为true
      this.checkValue = true;
    }
    }
     
   

    //     // 获取用户信息
    // let res = await getMyInfo();
    // this.userInfo = res.data.data;
    // if (res.data.data) {
    //    this.$router.push({ name: "myInfo",query:{"source":'user'} });
    // }
      
    // } else {
    //   this.password = "";
    // }
   }

   
  },
  methods: {
  
    async verify() {
      let nameVer = this.$refs.nameValue.validate(true);
      let passwdValue = this.$refs.password.validate(true);
      !nameVer ? this.$refs.nameValue.focus() : "";
      !passwdValue ? this.$refs.password.focus() : "";
      return nameVer && passwdValue;
    },
    toSign() {
      this.$router.push({ name: "register" });
    },
    forgetPasswd() {
      this.$router.push({ name: "findPassword" });
    },
   async  Login() {
      if (!(await this.verify())) {
        return;
      }
      if (this.checkValue) {

    //  判断当前cookies状态
   let cookies = localStorage.getItem("acceptCookie");
   if(cookies == 'true'){
      setCookies("userName", this.nameValue,30);
       setCookies(
          "userPsswd",
          CryptoJS.AES.encrypt(this.password, privateKey),
          30
        );
   }
      } else {
       removeCookies("userName");
       removeCookies("userPsswd");
      }

      // 登录 
      let loginParms = {
        email:encodeURIComponent(this.nameValue),
        password:encodeURIComponent(this.password)

      }
     let res =  await login(loginParms)
     console.log("res",res);
     debugger
     if(res.data.success){
       this.$router.push({ name: "myInfo",query:{"source":'user'} });

     }else{
           // 判断是否进行邮箱验证
       if(res.data.code == 10005){
           this.$router.push({ name: "emailnotification"});
       }else{
          this.$toast("error",res.data.msg);
       }
      
     }

     
    },
  },
};
</script>

<style lang="scss" scoped>
.contant {
  width: 100%;
  // height: 724px;
  // border: 1px solid red;
  .title-13 {
    font-family: AvenirNext-Medium;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .title-15 {
    font-family: AvenirNext-Medium;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .notAccount {
    font-family: AvenirNext-Medium;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #ffffff;
    span {
      cursor: pointer;
      font-family: AvenirNext-Medium;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #ff704a;
    }
  }
  .forgetpasswd {
    cursor: pointer;
    font-family: AvenirNext-Medium;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #ff704a;
  }
  .check {
    &::v-deep .v-input__control .v-input__slot .v-label {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      // line-height: 54px;
      letter-spacing: 0px;
      color: #ffffff;
      border-radius: 3px;
      border: solid 1px #b836eb;
    }
    &::v-deep
      .v-input__control
      .v-input__slot
      .v-input--selection-controls__input
      .theme--light {
      // color:#ffffff;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: solid 2px #b836eb;
    }
  }
  .foot {
    .login {
      width: 183px;
      height: 41px;
      background-image: linear-gradient(
        90deg,
        #b633f1 0%,
        #dc539c 50%,
        #ff704a 100%
      );
      border-radius: 5px;
      font-family: AvenirNext-Medium;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
  .passwd {
    width: 60%;
  }
  .name {
    width: 60%;
    height: 41px;
    background-color: #453344;
    border-radius: 10px;
    border: solid 1px #b836eb;

    &::v-deep .v-input__slot {
      min-height: 39px !important;
      border-radius: 10px;
      border: solid 1px #b836eb;
    }
    &::v-deep input {
      font-family: AvenirNext-Medium;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .logoin {
    width: 100%;
    margin-top: 106px;
    margin-left: 12%;
    .title {
      font-family: AvenirNext !important;
      font-size: 35px !important;
      font-weight: bold;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
  &.mobile {
    .name {
      width: 80%;
    }
    .passwd {
      width: 80%;
    }
  }
}
</style>

