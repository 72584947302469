import Cookies from 'js-cookie';

export const getCookies = (key) => Cookies.get(key);
export const setCookies = (key, value,timer) => Cookies.set(key, value,{
  expires: timer, // 存储期限
});
export const removeCookies = (key) => Cookies.remove(key);

export default {
  getCookies,
  setCookies,
  removeCookies
};
