import axios from "axios";
const service = axios.create({
    timeout: 300000,
  });


  //  获取apikey用户信息  http://192.168.111.81:5080/api/v1/key/info
export function getMyApiInfo(params) {
    return service.post('/apikey/key/info', 
        transObjToParamStr(params)
    )
}

  //  获取用户信息  POST /api/v1/user/info
  export function getMyInfo(params) {
    return service.post('/apikey/user/info', 
        transObjToParamStr(params)
    )
}

// 注册账户 POST /api/v1/user/register
export function registerUser(params) {
    return service.post('/apikey/user/register', 
        transObjToParamStr(params)
    )
}
// 用户登录 POST /api/v1/user/login
export function login(params) {
    return service.post('/apikey/user/login', 
        transObjToParamStr(params)
    )
}

// 确认邮箱  POST /api/v1/confirmEmail
export function confirmEmail(params) {
    return service.post('/apikey/confirmEmail', 
        transObjToParamStr(params)
    )
}

// 获取邮箱验证码 POST /api/v1/verifyCode
export function sendVerifyCode(params) {
    return service.post('/apikey/sendCode', 
        transObjToParamStr(params)
    )
}

//人机认证  /auth/siteverify
 export function checkToken(params) {
    return service.get("/apikey/auth/siteverify?"+transObjToParamStr(params)

    )}


// 修改密码 /api/v1/user/updatePassword
export function updatePassword(params) {
    return service.post('/apikey/user/updatePassword', 
        transObjToParamStr(params)
    )
}

//退出登录  POST /api/v1/user/loginOut 
export function loginOut(params) {
    return service.post('/apikey/user/loginOut', 
        transObjToParamStr(params)
    )
}

// Newsletter api/1.0/email/gather.do
export function Newsletter(params) {
    return service.post("https://market-service.upticknft.com/api/1.0/email/gather.do", 
    transObjToParamStr(params)
    )
}

function transObjToParamStr(object) {
   
let arr=[]
    for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
            const element = object[key];
            arr.push(`${key}=${element}`);
        }
    }
    return arr.join('&');
}